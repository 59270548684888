import { Component, Input, OnInit } from '@angular/core';
import { videoscap } from 'src/app/data/capacitaciones';
import { videos } from 'src/app/data/videos-sge';
import { Video } from 'src/app/models/video';

@Component({
  selector: 'app-seccion-videos-calificaciones',
  templateUrl: './seccion-videos-calificaciones.component.html',
  styleUrls: ['./seccion-videos-calificaciones.component.scss']
})
export class SeccionVideosCalificacionesComponent implements OnInit {
  @Input() videoElegido: any;
  listaVideosPrincipales = videoscap.sort((a,b)=> b.id - a.id);
  listaVideosUltimo3Videos:any[]=videos.filter(v=> [8,9,21].includes(v.id));
  listaVideosPrincipalesSGe = videos.filter(v=> [8,9].includes(v.id));
  listaVideosPrincipalesTableros = videos.filter(v=> [18].includes(v.id));
  constructor() { }

  ngOnInit(): void {
    console.log('ultimos 3 videos:',this.listaVideosUltimo3Videos)
  }
  getClass(video: Video): string {
    return video.id === this.videoElegido.id ? 'bg-dark text-white shadow rounded' : '';
  }

  getObtenerIdVideo(urlyoutube:string):string{
    let pos = urlyoutube.lastIndexOf('/');
    pos++;
    return 'https://www.youtube.com/embed/'+urlyoutube.substring(pos,urlyoutube.length);
  }
  getUrlVideo(urlVideo: string):string{
    return "https://youtu.be/"+urlVideo.slice(30,urlVideo.length);

  }

}

